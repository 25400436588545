@mixin reset-ul-li {
  ul {
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      &::before {
        content: none;
      }

      a {
        &::after {
          content: none !important;
        }
      }
    }
  }
}

@mixin button-reset {
  border: unset;
  background: unset;
  padding: 0;
  cursor: pointer;
}
