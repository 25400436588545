.footer {
  $link-color: #ecebea;
  background-color: $dark-grey;
  color: #fff;
  position: relative;
  z-index: 2;

  .socials-column {
    border-bottom: 1px solid #707070;
    justify-content: center;

    .socials-container {
      @include reset-ul-li;

      ul {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;

        width: 100%;
        padding: 2rem;
        gap: 3rem;

        @include desktop {
          max-width: 400px;
        }
      }

      a {
        display: grid;
        place-items: center;
      }
    }

    .socials-slider {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;

      .socials-slide {
        padding: 2rem;

        a {
          display: grid;
          place-items: center;
        }
      }

      .prev,
      .next {
        display: grid;
        place-items: center;
        text-decoration: none;
        user-select: none;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        width: 44px;
        height: 44px;
      }

      .embla-slider {
        @include reset-ul-li;
        overflow: hidden;
        .embla-container {
          display: flex;
          height: 100%;

          .embla-slide {
            min-width: 0;
            height: 100%;
            flex: 0 0 33.3333333333%;
          }
        }
      }
    }
  }

  .second {
    border-bottom: 1px solid #707070;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    @include tablet {
      padding: 2rem;
    }

    @include desktop {
      flex-direction: row;
    }

    .legal-menu-row {
      border-right: 1px solid #707070;

      .legal-menu {
        display: grid;
        grid-auto-flow: row;
        justify-content: center;
        row-gap: 0.438rem;

        a {
          font-size: 0.875rem;
          color: $link-color;
          text-decoration: none;
          @include line__animtion;
        }
      }
    }

    .news-letter-row {
      display: grid;
      justify-content: center;
      border-right: none;

      @include desktop {
        border-right: 1px solid #707070;
      }

      .news-letter {
        padding-left: 2rem;
        padding-right: 2rem;
        max-width: 400px;
        display: grid;
        place-items: center;
        text-align: center;

        @include desktop {
          place-items: start;
          text-align: start;
        }

        .title {
          font-family: $sans-serif-bold;
          font-size: 14px;
          color: $link-color;
        }

        .content {
          p {
            font-size: 14px;
            color: $link-color;
          }
        }
      }
    }
  }

  .third {
    padding-top: 2.063rem;

    @include desktop {
      padding: 2.063rem 0;
    }

    .legal-menu-column {
      .legal-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.4rem;

        @include tablet {
          max-width: 400px;
        }

        @include desktop {
          justify-content: flex-start;
          max-width: none;
        }

        a,
        > span {
          position: relative;
          color: $link-color;
          padding: 0 1rem;
          margin: 0.2rem 0;
          text-decoration: none;
          font-size: 0.875rem;

          &:nth-last-child(n + 2)::after {
            content: "|";
            position: absolute;
            right: 0;
          }

          @include line__animtion;
        }
      }
    }
  }

  .review-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    // margin: 1.5rem 0 2rem;
    width: 100%;

    a {
      text-decoration: none;
    }

    @include tablet {
      flex-direction: row;
      gap: 1;
    }

    @include desktop {
      flex-direction: column;
      margin-bottom: 0;
      gap: 1.25rem;
    }

    .review {
      background: #fff;

      * {
        color: $dark-grey;
      }

      display: flex;
      place-items: center;
      padding: 0.4rem 1rem;
      border-radius: 2px;

      .logo-container {
        margin-right: 1rem;

        display: grid;
        place-items: center;
      }

      .content {
        display: grid;

        span {
          line-height: 0;
          font-size: 0.875rem;
        }

        .rating {
          display: flex;
          justify-content: space-around;
          align-items: center;
          // gap: 1rem;

          span {
            font-size: 1rem;
            padding-right: 3px;
            padding-top: 3px;
          }

          .stars {
            // padding-top: 3px;
            flex-grow: 1;
            display: flex;
          }
        }

        .review-numbers {
          span {
            font-size: 0.875rem;
            line-height: 0;
          }
        }
      }
    }
  }

  p {
    color: #fff;
  }

  .touch-menu {
    padding: 2rem 0;
    border-bottom: 1px solid #707070;
    .legal-menu-column {
      .legal-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;
        @include tablet {
          flex-direction: row;
        }

        a,
        > span {
          position: relative;
          color: $link-color;
          padding: 0 1rem;
          margin: 0.2rem 0;
          text-decoration: none;
          font-size: 0.875rem;

          @include tablet {
            &:nth-last-child(n + 2)::after {
              content: "|";
              position: absolute;
              right: 0;
            }
          }

          @include line__animtion;
        }
      }
    }
  }

  @include reset-ul-li;

  #menuitem-privacysettings {
    @include button-reset;
  }
}
