.accordion {
  &.has-grey-bg {
    .box-active {
      .accordion-element {
        border: 2px solid $white;
      }
    }

    .box-inactive {
      .accordion-element {
        border-top: 2px solid $white;
      }
    }
  }

  &.has-darkgrey-bg,
  &.has-dark-grey-bg,
  &.has-white-bg {
    .box-active {
      .accordion-element {
        border: 2px solid $light-grey;
      }
    }
    .box-inactive {
      .add-border {
        border-top: 2px solid $light-grey;
      }
    }
  }

  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .box-active {
      .accordion-element {
        border: 2px solid $light-grey;
      }
    }
  }

  .accordion-elements-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;

    @include tablet {
      padding: 0;
    }

    .accordion-element {
      cursor: pointer;

      .accordion-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.875rem 2.625rem;

        .summary-content {
          display: flex;
          gap: 1rem;
          align-items: center;
        }
      }

      .accordion-content {
        padding: 0 2.625rem 1.875rem;
      }

      .element-headline {
        color: $dark-grey;
        margin-bottom: 0;
      }

      .element-index {
        font-size: 40px;
        line-height: 1;
        @include tablet {
          font-size: 60px;
        }

        font-family: $sans-serif-bold;
        color: $grey;
        display: inline-flex;
      }

      .icon-wrapper {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
      }

      .element-action-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .element-action {
          background: transparent;
          border: none;
          cursor: pointer;
          color: inherit;
        }
      }
    }

    &.box-active {
      .frame-box-wrapper {
        &:hover {
          &::after,
          &::before {
            opacity: 1;
            width: 50px;
            height: 50px;

            @include desktop {
              width: 40px;
              height: 40px;
            }
          }

          .corner-div {
            opacity: 1;
            width: 50px;
            height: 50px;
            @include desktop {
              width: 40px;
              height: 40px;
            }
          }
        }
      }

      gap: 1.25rem;
    }

    &.box-inactive {
      .frame-box-wrapper {
        &::after,
        &::before {
          content: none;
        }

        .corner-div {
          display: none;
        }
      }
    }
  }
}

.accordion-list {
  &.has-white-bg {
    .parallax span {
      color: #c1c1c11a;
    }
  }
  &.has-grey-bg {
    .parallax span {
      color: #ffffff8c;
    }
  }
  &.has-gradient-bg {
    .parallax span {
      color: #ffffff8c;
    }
  }
  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .parallax span {
      color: #c1c1c10d;
    }
  }

  .accordion-list-wrapper {
    margin: 0;
    .accordion-list {
      display: grid;
      grid-template-columns: 80% 20%;
      padding: 2rem 0;

      &:hover {
        .info-wrapper {
          .headline {
            animation: gradient__text 0.6s
              cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
          }
        }
      }

      border-bottom: 1px solid $dark-grey;

      .info-wrapper {
        .headline {
          &::selection {
            -webkit-text-fill-color: initial;
            -moz-text-fill-color: initial;
          }
          margin-bottom: 1rem;
          text-transform: uppercase;
          font-family: $sans-serif-bold;

          position: relative;
          z-index: 31;

          background-image: linear-gradient(
            90deg,
            rgba(8, 65, 219, 1) 0%,
            rgba(82, 49, 199, 1) 15%,
            rgba(155, 33, 179, 1) 30%,
            rgba(209, 23, 148, 1) 45%,
            rgba(241, 17, 130, 1) 60%,
            rgba(225, 24, 104, 1) 75%,
            rgba(210, 31, 81, 1) 90%,
            rgba(192, 39, 51, 1) 100%
          );

          background-size: cover;
          background-color: $dark-grey;

          background-repeat: no-repeat;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
          background-position: 0 2.5rem;

          animation: black__text 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
            forwards;
        }

        .text {
          font-size: 0.75rem;
          text-transform: uppercase;
        }
      }

      .arrow-wrapper {
        padding-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .parallax {
    span {
      margin-right: 100px;
      font-family: $sans-serif-bold;
      text-transform: uppercase;

      font-size: 20vw;
      line-height: 1.2;

      @include desktop {
        font-size: 200px;
      }
    }
  }

  @keyframes gradient__text {
    0% {
      background-position: 0 2.5rem;
    }
    10% {
      background-position: 0 2.25rem;
    }
    20% {
      background-position: 0 1.75rem;
    }
    30% {
      background-position: 0 1.5rem;
    }
    40% {
      background-position: 0 1.25rem;
    }
    50% {
      background-position: 0 1rem;
    }
    60% {
      background-position: 0 0.75rem;
    }
    70% {
      background-position: 0 0.5rem;
    }
    80% {
      background-position: 0 0.25rem;
    }
    100% {
      background-position: 0 0;
    }
  }

  @keyframes black__text {
    0% {
      background-position: 0 0;
    }
    10% {
      background-position: 0 0.25rem;
    }
    20% {
      background-position: 0 0.5rem;
    }
    30% {
      background-position: 0 0.75rem;
    }
    40% {
      background-position: 0 1rem;
    }
    50% {
      background-position: 0 1.5rem;
    }
    60% {
      background-position: 0 1.75rem;
    }
    70% {
      background-position: 0 2rem;
    }
    80% {
      background-position: 0 2.25rem;
    }
    100% {
      background-position: 0 2.5rem;
    }
  }
}
